import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import Img from "gatsby-image"

import SEO from "../components/seo"

const blockVariants = {
  // initial: { y: 20, opacity: 0 },
  // enter: { y: 0, opacity: 1, ease: [0.43, 0.13, 0.23, 0.96] },
  // exit: {
  //   y: 20,
  //   opacity: 0,
  //   transition: { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] },
  // },
}

const IndexPage = ({ location }) => (
  <motion.article
    initial="initial"
    animate="enter"
    exit="exit"
    variants={{
      exit: {
        opacity: 0,
        transition: { staggerChildren: 0.1 },
      },
      enter: {
        opacity: 1,
        transition: { staggerChildren: 0.1 },
      },
      initial: { opacity: 0 },
    }}
    className="relative flex flex-col flex-1 ease-in-out main transition-slowest transition-color"
  >
    {/* <SEO title="Home" /> */}
    <motion.div className="flex flex-col flex-1 p-6 overflow-hidden md:p-10">
      <motion.header className={`relative`} variants={blockVariants}>
        <h1>Files</h1>
      </motion.header>
      <motion.div className="flex-1" variants={blockVariants}>
        <h2>Content</h2>
      </motion.div>
    </motion.div>
  </motion.article>
)

export default IndexPage

// export const pageQuery = graphql`
//   query allPortfolioItems {
//     allPrismicPortfolioItem {
//       edges {
//         node {
//           uid
//           first_publication_date
//           last_publication_date
//           data {
//             title {
//               text
//             }
//             meta_short {
//               html
//               text
//             }
//             cover_image {
//               url
//               copyright
//               alt
//               localFile {
//                 childImageSharp {
//                   fluid(maxWidth: 1200, quality: 90) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
